import React, { useState, useEffect, useRef } from "react";
import {
    CardBody, CardHeader, Container,
    Row, Col, Card, CardText, CardTitle,
    Form, Label, Input, Button, Table,
    Pagination,
    PaginationItem,
    PaginationLink, Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import toast, { Toaster } from 'react-hot-toast';
import ReactPaginate from "react-paginate";
// import { URL } from "../../Apiurls";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, getAllWebData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import { CSVLink } from "react-csv"
import { useReactToPrint } from "react-to-print"

const Leads = () => {
    const [banner, setbanner] = useState([])
    const [banner1, setbanner1] = useState([])
    // get all function

    const getAllbenners = async () => {
        const resonse = await addData("getForms")
        var _data = resonse
        setbanner(_data.data.data)
        setbanner1(_data.data.data.map((data) => (
            {
                "Date": data.logCreatedDate,
                "Full Name": data.fullname,
                "Phone": data.phone,
                "Email": data.email,
                "Area": data.area,
                "Address": data.address,
                "Motorcycle": data.motorcycleType,
                "Plan": data.serviceTitle,
                "Problem": data.problem,
            }
        )))
    }

    useEffect(() => {
        getAllbenners();
    }, []);


    const [listPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);

    const pagesVisited = pageNumber * listPerPage;
    const lists = banner.slice(pagesVisited, pagesVisited + listPerPage);
    const pageCount = Math.ceil(banner.length / listPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    const [forms, setforms] = useState([]);
    console.log(forms)

    // Search fuction
    const handleSearch = async (e) => {
        const resonse = await addData("getForms?searchQuery=" + e.target.value)
        var _data = resonse
        setbanner(_data.data.data)
        setbanner1(_data.data.data.map((data) => (
            {
                "Date": data.logCreatedDate,
                "Full Name": data.fullname,
                "Phone": data.phone,
                "Email": data.email,
                "Area": data.area,
                "Address": data.address,
                "Motorcycle": data.motorcycleType,
                "Plan": data.serviceTitle,
                "Problem": data.problem,
            }
        )))
    };



    // excel
    const csvReport = {
        filename: "Leads.csv",
        // headers: headers,
        data: banner1,
    }
    // pdf 
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="BIKERS24" breadcrumbItem="Leads" />
                    <Row>

                        <Col md={12}>
                            <Card>
                                <CardBody >
                                    <Row>
                                        <Col md="6">
                                            <div style={{ float: "left" }}>
                                                <Input
                                                    type="text"
                                                    name="search"
                                                    onChange={handleSearch}
                                                    className="form-control" placeholder="Search.." />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div style={{ float: "right" }}>
                                                <CSVLink {...csvReport}>
                                                    <Button type="button" color="success m-1" outline>
                                                        Excel <i className="fas fa-file-excel"></i>
                                                    </Button>
                                                </CSVLink>
                                                {/* <Button
                                                    onClick={handlePrint}
                                                    type="button"
                                                    color="danger m-1"
                                                    outline
                                                >
                                                    PDF <i className="fas fa-file-pdf"></i>
                                                </Button> */}
                                            </div>
                                        </Col>
                                    </Row>

                                    <div>
                                        <div className="table-responsive">
                                            <div ref={componentRef} >
                                                <Table className="table table-bordered mb-4 mt-3">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th> S No</th>
                                                            <th> Date</th>
                                                            <th> Name</th>
                                                            <th> Email</th>
                                                            <th> Mobile</th>
                                                            <th> Area</th>
                                                            <th>Address</th>
                                                            <th>Motorcycle</th>
                                                            <th>Plan</th>
                                                            <th>Problem</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-center">
                                                        {lists.length == 0 ? (
                                                            <tr className="text-center">
                                                                <td colSpan={8}>
                                                                    <h5>No Data...</h5>
                                                                </td>
                                                            </tr>

                                                        ) : (
                                                            <>
                                                                {lists.map((data, key) => (
                                                                    <tr key={key}>
                                                                        <th>{(pageNumber - 1) * 10 + key + 11}</th>
                                                                        <td> {data.logCreatedDate.slice(0, 10)}</td>
                                                                        <td> {data.fullname}</td>
                                                                        <td> {data.email}</td>
                                                                        <td> {data.phone}</td>
                                                                        <td> {data.area}</td>
                                                                        <td>{data.address}</td>
                                                                        <td>{data.motorcycleType}</td>
                                                                        <td>{data.serviceTitle}</td>
                                                                        <td>{data.problem}</td>
                                                                    </tr>
                                                                ))}
                                                            </>

                                                        )}

                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div className="mt-3" style={{ float: "right" }}>

                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount}
                                                    onPageChange={changePage}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"disabled"}
                                                    activeClassName={"active"}
                                                    total={lists.length}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </Container>

                <Toaster />
            </div>
        </React.Fragment >
    )
}

export default Leads
