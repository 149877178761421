import React from "react"
import { Redirect } from "react-router-dom"
// Profile
import UserProfile from "../pages/Authentication/user-profile"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Otp from "pages/Authentication/Otp"
import Setpwd from "pages/Authentication/Setpwd"

import Leads from '../pages/Bikers24/Leads'
import Plans from '../pages/Bikers24/Plans'
import Privacypolicy from '../pages/Bikers24/Privacypolicy'
import Refund from '../pages/Bikers24/Refund'
import Termsandconditions from '../pages/Bikers24/Termsandconditions'


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/profile", component: UserProfile },
  { path: "/leads", component: Leads },
  { path: "/plans", component: Plans },
  { path: "/privacypolicy", component: Privacypolicy },
  { path: "/refundpolicy", component: Refund },
  { path: "/termsandconditions", component: Termsandconditions },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/otp", component: Otp },
  { path: "/setpassword", component: Setpwd },
]

export { publicRoutes, authProtectedRoutes }
