import React, { useState, useEffect } from "react";
import {
    CardBody, CardHeader, Container,
    Row, Col, Card, CardText, CardTitle,
    Form, Label, Input, Button, Table,
    Pagination,
    PaginationItem,
    PaginationLink, Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import toast, { Toaster } from 'react-hot-toast';
import ReactPaginate from "react-paginate";
// import { URL } from "../../Apiurls";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData, getAllData } from "Servicescalls"
import { imgUrl } from "Baseurls"

const Plans = () => {
    const [modal_small, setmodal_small] = useState(false);
    const [banner, setbanner] = useState([])
    const [form, setform] = useState({ title: "", price: "", offerPrice: "", description: "", })
    const [form1, setform1] = useState([])
    console.log(form1)
    const [form2, setform2] = useState([])


    function tog_small() {
        setmodal_small(!modal_small);
        removeBodyCss();
    }

    const handleChange = (e) => {
        let myUser = { ...form };
        myUser[e.target.name] = e.target.value;
        setform(myUser);
    };
    const handleChange1 = (e) => {
        let myUser = { ...form1 };
        myUser[e.target.name] = e.target.value;
        setform1(myUser);
    };

    const [inputList, setInputList] = useState([{ value: "" }])
    const [inputList1, setInputList1] = useState([{ value: "" }])

    const handleInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...inputList]
        list[index][name] = value
        setInputList(list)

    }
    const handleInputChange1 = (e, index) => {
        const { name, value } = e.target
        const list = [...inputList1]
        list[index][name] = value
        setInputList1(list)

    }

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList]
        list.splice(index, 1)
        setInputList(list)
    }
    const handleRemoveClick1 = index => {
        const list = [...inputList1]
        list.splice(index, 1)
        setInputList1(list)
    }


    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { value: "" }])
    }
    const handleAddClick1 = () => {
        setInputList1([...inputList1, { value: "" }])
    }


    const [items, setItems] = useState([]);
    const [userinfo, setuserinfo] = useState([]);
    console.log(items.token)
    console.log(userinfo)

    // get all function

    const getAllbenners = async () => {
        const resonse = await addData("getServices")
        var _data = resonse
        setbanner(_data.data.data)
    }

    useEffect(() => {
        getAllbenners();
    }, []);


    const [listPerPage] = useState(5);
    const [pageNumber, setPageNumber] = useState(0);

    const pagesVisited = pageNumber * listPerPage;
    const lists = banner.slice(pagesVisited, pagesVisited + listPerPage);
    const pageCount = Math.ceil(banner.length / listPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    // Add function

    const addbenners = async (e) => {
        e.preventDefault()
        const bodydata = {
            title: form.title,
            price: form.price,
            offerPrice: form.offerPrice,
            duration: form.duration,
            description: form.description,
            points: inputList,
        }

        try {
            const resonse = await addData("updateService", bodydata)
            var _data = resonse
            console.log(_data)
            setform({ title: "", price: "", offerPrice: "", description: "", })
            setInputList([{ value: "" }])
            setShow(false)
            toast.success(_data.data.message)
            getAllbenners()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    // Edit fuction
    const editbenners = async (e) => {
        e.preventDefault()
        const bodydata = {
            serviceId: form1._id,
            title: form1.title,
            price: form1.price,
            offerPrice: form1.offerPrice,
            duration: form1.duration,
            description: form1.description,
            points: inputList1,
        }
        try {
            const resonse = await addData("updateService", bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setInputList1([{ value: "" }])
            setmodal_small(false)
            getAllbenners()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    // Delete fuction

    const deletebenners = async (data) => {
        const bodydata = {
            serviceId: data._id,
        }
        try {
            const resonse = await addData("deleteService", bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            getAllbenners()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const manageDelete = (data) => {
        const confirmBox = window.confirm("Do you really want to Delete?");
        if (confirmBox === true) {
            deletebenners(data);
        }
    };

    const getpopup = (data) => {
        setform1(data);
        setInputList1(data.points)
        tog_small()
    };

    const [forms, setforms] = useState([]);
    console.log(forms)

    // Search fuction
    const handleSearch = async (e) => {
        const resonse = await getAllData("getServices?searchQuery=" + e.target.value)
        var _data = resonse
        setbanner(_data.data.data)
    };

    const [show, setShow] = useState(false)
    const showaddevent = () => {
        setShow(!show)
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="BIKERS24" breadcrumbItem="Plans" />
                    {/* {permissioins.banner === true || roles === "admin" ? ( */}

                    <Row>
                        {show == true ? (
                            <Col md={12}>
                                <Card>
                                    <CardHeader className="bg-white">
                                        <CardTitle>Add Plan</CardTitle>
                                    </CardHeader>
                                    <CardBody >

                                        <Form onSubmit={(e) => {
                                            addbenners(e);
                                        }}>
                                            <Row>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            Name <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Name"
                                                            required
                                                            name="title"
                                                            value={form.title}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                        />
                                                    </div> </Col>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            Price <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Enter Price"
                                                            required
                                                            name="price"
                                                            value={form.price}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                        />
                                                    </div> </Col>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            Offer Price <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Enter Offer Price"
                                                            required
                                                            name="offerPrice"
                                                            value={form.offerPrice}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                        />
                                                    </div> </Col>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            Select Duration <span className="text-danger">*</span>
                                                        </Label>
                                                        <select
                                                            className="form-select"
                                                            required
                                                            name="duration"
                                                            value={form.duration}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="3 Months">3 Months</option>
                                                            <option value="6 Months">6 Months</option>
                                                            <option value="12 Months">12 Months</option>
                                                        </select>
                                                    </div> </Col>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            Description <span className="text-danger">*</span>
                                                        </Label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Description"
                                                            required
                                                            name="description"
                                                            value={form.description}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                        />
                                                    </div> </Col>
                                                <Col md="9">
                                                    <div className="mt-4">
                                                        <Label>What's Included</Label>
                                                        <Row>
                                                            {inputList.map((x, i) => {
                                                                return (
                                                                    <div key={i} className="box row">
                                                                        <Col md='5' sm='12' className='mb-1'>
                                                                            {/* <Label className='form-label' for='nameMulti'>
                                                                Item Name <span className="text-danger">*</span>
                                                            </Label> */}
                                                                            <Input type='text' id='nameMulti' placeholder='Enter Name' name="value"
                                                                                value={x.value}
                                                                                onChange={e => handleInputChange(e, i)}
                                                                                required
                                                                            // pattern="^[a-zA-Z]*$"
                                                                            />
                                                                        </Col>
                                                                        <Col sm="3">
                                                                            <div className="btn-box">
                                                                                {inputList.length !== 1 && <button
                                                                                    className="mr10 btn btn-outline-danger btn-sm m-1" type="button"
                                                                                    onClick={() => handleRemoveClick(i)}>Remove <i className="bx bx-x-circle"></i></button>}
                                                                                {inputList.length - 1 === i && <button className="btn btn-sm btn-outline-info m-1" onClick={handleAddClick}>Add <i className="bx bx-plus-circle"></i></button>}
                                                                            </div>
                                                                        </Col>

                                                                    </div>
                                                                )
                                                            })}
                                                        </Row>
                                                    </div>
                                                </Col>

                                            </Row>
                                            <div className="mt-4" style={{ float: "right" }}>
                                                <Button className="m-1" color="success" type="submit">
                                                    Submit <i className="fas fa-check-circle"></i>
                                                </Button>
                                                <Button className="m-1" onClick={() => { showaddevent() }} color="danger" type="button">
                                                    Cancel <i className="bx bx-x-circle"></i>
                                                </Button>

                                            </div>
                                        </Form>

                                    </CardBody>
                                </Card>
                            </Col>
                        ) : ""}
                        <Col md={12}>
                            <Card>


                                <CardBody >
                                    <Row>
                                        <Col md="6">
                                            <Button onClick={() => { showaddevent() }} color="primary">Add Plan <i className="bx bx-add-to-queue" /></Button>
                                        </Col>
                                        <Col md="6">
                                            <div style={{ float: "right" }}>
                                                <Input
                                                    type="text"
                                                    name="search"
                                                    onChange={handleSearch}
                                                    className="form-control" placeholder="Search.." />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div>
                                        <div className="table-responsive">

                                            <Table className="table table-bordered mb-4 mt-3">
                                                <thead>
                                                    <tr>
                                                        <th>S No</th>
                                                        <th> Name</th>
                                                        <th> Description</th>
                                                        <th> Duration</th>
                                                        <th> Price</th>
                                                        <th> Offer Price</th>
                                                        <th>Status</th>
                                                        <th style={{ width: "100px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {lists.map((data, key) => (
                                                        <tr key={key}>
                                                            <th>{(pageNumber - 1) * 10 + key + 11}</th>
                                                            <td>{data.title}</td>
                                                            <td style={{ width: "400px" }}>
                                                                {data.description}
                                                            </td>
                                                            <td>{data.duration} </td>
                                                            <td>₹ {data.price}  </td>
                                                            <td>₹ {data.offerPrice} </td>
                                                            <td>{data.status == true ? "Active" : "Inactive"} </td>
                                                            <td>
                                                                <Button onClick={() => {
                                                                    getpopup(data);
                                                                }}
                                                                    className="mr-2" style={{ padding: "6px", margin: "3px" }} color="success">
                                                                    <i className="bx bx-edit "></i>
                                                                </Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        manageDelete(data);
                                                                    }}
                                                                    style={{ padding: "6px", margin: "3px" }} color="danger">
                                                                    <i className="bx bx-trash"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>

                                            <div className="mt-3" style={{ float: "right" }}>
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount}
                                                    onPageChange={changePage}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"disabled"}
                                                    activeClassName={"active"}
                                                    total={lists.length}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>


                <Modal
                    // size="sm"
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small();
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="mySmallModalLabel"
                        >
                            Edit Plan
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={(e) => { editbenners(e) }}>
                            <Row>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Name <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Name"
                                            required
                                            name="title"
                                            value={form1.title}
                                            onChange={(e) => {
                                                handleChange1(e);
                                            }}
                                        />
                                    </div> </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Price <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Price"
                                            required
                                            name="price"
                                            value={form1.price}
                                            onChange={(e) => {
                                                handleChange1(e);
                                            }}
                                        />
                                    </div> </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Offer Price <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Offer Price"
                                            required
                                            name="offerPrice"
                                            value={form1.offerPrice}
                                            onChange={(e) => {
                                                handleChange1(e);
                                            }}
                                        />
                                    </div> </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Select Duration <span className="text-danger">*</span>
                                        </Label>
                                        <select
                                            className="form-select"
                                            required
                                            name="duration"
                                            value={form1.duration}
                                            onChange={(e) => {
                                                handleChange1(e);
                                            }}
                                        >
                                            <option value="">Select</option>
                                            <option value="3 Months">3 Months</option>
                                            <option value="6 Months">6 Months</option>
                                            <option value="12 Months">12 Months</option>
                                        </select>
                                    </div> </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Status <span className="text-danger">*</span>
                                        </Label>
                                        <select
                                            className="form-select"
                                            required
                                            name="status"
                                            value={form1.status}
                                            onChange={(e) => {
                                                handleChange1(e);
                                            }}
                                        >
                                            <option value="">Select</option>
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>
                                        </select>
                                    </div> </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Description <span className="text-danger">*</span>
                                        </Label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Description"
                                            required
                                            name="description"
                                            value={form1.description}
                                            onChange={(e) => {
                                                handleChange1(e);
                                            }}
                                        />
                                    </div> </Col>
                                <Col md="12">
                                    <div className="mt-4">
                                        <Label>What's Included</Label>
                                        <Row>
                                            {inputList1.map((x, i) => {
                                                return (
                                                    <div key={i} className="box row">
                                                        <Col md='8' sm='12' className='mb-1'>
                                                            {/* <Label className='form-label' for='nameMulti'>
                                                                Item Name <span className="text-danger">*</span>
                                                            </Label> */}
                                                            <Input type='text' id='nameMulti' placeholder='Enter Name' name="value"
                                                                value={x.value}
                                                                onChange={e => handleInputChange1(e, i)}
                                                                required
                                                            // pattern="^[a-zA-Z]*$"
                                                            />
                                                        </Col>
                                                        <Col sm="4">
                                                        <div className="btn-box">
                                                                                {inputList1.length !== 1 && <button
                                                                                    className="mr10 btn btn-outline-danger btn-sm m-1" type="button"
                                                                                    onClick={() => handleRemoveClick1(i)}>Remove <i className="bx bx-x-circle"></i></button>}
                                                                                {inputList1.length - 1 === i && <button className="btn btn-sm btn-outline-info m-1" onClick={handleAddClick1}>Add <i className="bx bx-plus-circle"></i></button>}
                                                                            </div>

                                                        
                                                        </Col>

                                                    </div>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </Col>

                            </Row>


                            <div style={{ float: "right" }}>
                                <Button onClick={() => {
                                    setmodal_small(false);
                                }} color="danger" type="button">
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>

                            </div>
                        </Form>
                    </div>
                </Modal>

                <Toaster />
            </div>
        </React.Fragment >
    )
}

export default Plans
